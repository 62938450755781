@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Roboto", sans-serif;
  @apply text-black-100;
}

.gotham-book {
  font-family: "Gotham Book";
}

.gotham-bold {
  font-family: "Gotham Bold";
}

.poppins {
  font-family: "Poppins", sans-serif;
}

.roboto {
  font-family: "Roboto", sans-serif;
}

.w-1-1 img,
.w-1-1 div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.w-1-1 div {
  @apply bg-white-200 dark:bg-dark-200;
}

@responsive {
  .w-1-1 {
    width: 100%;
    position: relative;
    padding-top: 100%;
  }
}

.loader {
  height: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loader div {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  animation: loading 2s linear infinite forwards;
}

.loader div:nth-child(1) {
  animation-delay: 0s;
}

.loader div:nth-child(2) {
  margin: 0 5px;
  animation-delay: 0.5s;
}

.loader div:nth-child(3) {
  animation-delay: 1s;
}

@keyframes loading {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}

.text {
  overflow: hidden;
  height: 1.5rem;
  width: 1024px;
}

.text-container,
.sinopsis {
  position: relative;
}

.sinopsis {
  @apply max-h-56 overflow-hidden;
}

.text-container:after {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  @apply bg-gradient-to-r from-transparent to-white-100 dark:to-dark-100;
  width: 50%;
  content: "";
}

.sinopsis:after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 32px;
  @apply bg-gradient-to-b from-transparent to-white-200 dark:to-dark-200;
  content: "";
}

.noreset blockquote,
.noreset figure {
  margin: 1em 40px;
}

.noreset p {
  @apply my-3 text-justify;
}

.noreset h1 {
  font-size: 2em;
  font-weight: bold;
  margin: 0.67em 0;
}

.noreset h2 {
  font-size: 1.5em;
  font-weight: bold;
  margin: 0.83em 0;
}

.noreset h3 {
  font-size: 1.17em;
  font-weight: bold;
  margin: 1em 0;
}

.noreset h4 {
  font-size: 1em;
  font-weight: bold;
  margin: 1.33em 0;
}

.noreset h5 {
  font-size: 0.83em;
  font-weight: bold;
  margin: 1.67em 0;
}

.noreset h6 {
  font-size: 0.67em;
  font-weight: bold;
  margin: 2.33em 0;
}

.noreset ul,
.noreset menu {
  list-style-type: disc;
  margin: 1em 0;
  padding: 0 0 0 40px;
}

.noreset ol {
  list-style-type: decimal;
  margin: 1em 0;
  padding: 0 0 0 40px;
}

.nl-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.nl-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.rc-slider-track {
  @apply bg-secondary-100 !important;
}

.rc-slider-handle {
  @apply border-primary-100 !important;
}

.breadcrumb {
  @apply bg-primary-50 py-2 px-3 mb-5 inline-table;
}

.breadcrumb li {
  display: inline;
  @apply text-sm text-black-300;
}

.breadcrumb li a {
  @apply text-white-300;
}

.breadcrumb li div {
  display: inline-block;
}

.breadcrumb li + li:before {
  content: "//";
  @apply mx-2;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
